<template>
  <div>
    <validation-provider
      :rules="rules"
      :name="name"
      v-bind="$attrs"
      v-slot="{ errors, valid, invalid, validated }"
    >
     <b-form-group >
        <label v-if="label" class="form-control-label">
          {{ label }}<span v-if="required">&nbsp;*</span>
        </label>
        
        <slot v-bind="slotData">
          <input
            :value="value"
            hidden="true"
            v-on="listeners"
            v-bind="$attrs"
            :valid="valid"
            :required="required"
        
           
          />
        </slot>

        <div class="quill" :class="[
              { 'is-valid': valid && validated && successMessage },
              { 'is-invalid': invalid && validated }]
             ">
          <div :id="toolbarId">
            <div class="ql-formats">
              <button class="ql-bold"></button>
              <button class="ql-italic"></button>
              <button class="ql-underline"></button>
              <button class="ql-link"></button>
              <button class="ql-blockquote"></button>
              <button class="ql-code"></button>
              <button class="ql-image"></button>
              <button type="button" class="ql-list" value="ordered"></button>
              <button type="button" class="ql-list" value="bullet"></button>
            </div>
          </div>
          <div :id="editorId" :name="name" class="" ref="editor"></div>
          <slot name="error">
          <div v-if="errors[0]" class="invalid-feedback" style="display: block">
            {{ errors[0] }}
          </div>
        </slot>
        </div>
        
     </b-form-group>
    </validation-provider>
  </div>
</template>
<style >

.ql-clipboard {
  display:none
}
.quill.is-invalid .ql-editor, .quill.is-invalid .ql-toolbar {
  border-color:red
}
</style>

<script>
export default {
  name: "html-editor",
  props: {
    value: {
      type: String,
      default: "",
    },
    label: {
      type: String,
    },
    required: {
      type: Boolean,
    },
    successMessage: {
      type: String,
      description: "Input success message",
      default: "",
    },
    rules: {
      type: [String, Array, Object],
      description: "Vee validate validation rules",
      default: "",
    },
    name: String,
  },
  computed: {
     listeners() {
      return {
        ...this.$listeners,
      };
    },
    slotData() {
      return {
        error: this.error,
        ...this.listeners,
      };
    },
  },
  data() {
    return {
      editor: null,
      content: null,
      lastHtmlValue: "",
      editorId: null,
      toolbarId: null,
    };
  },
  methods: {
    initialize(Quill) {
      this.editor = new Quill(`#${this.editorId}`, {
        theme: "snow",
        modules: {
          toolbar: `#${this.toolbarId}`,
        },
      });

      if (this.value && this.value.length > 0) {
        this.editor.pasteHTML(this.value);
      }

      let editorRef = this.$refs.editor;
      let node = editorRef.children[0];
      this.editor.on("text-change", () => {
        let html = node.innerHTML;
        if (html === "<p><br></p>") {
          html = "";
        }
        this.content = html;
        this.$emit("input", this.content);
      });
    },
    pasteHTML() {
      if (!this.editor) {
        return;
      }
      this.editor.pasteHTML(this.value);
    },
    randomString() {
      let text = "";
      let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";

      for (let i = 0; i < 5; i++)
        text += possible.charAt(Math.floor(Math.random() * possible.length));

      return text;
    },
  },
  async mounted() {
    let Quill = await import("quill");
    Quill = Quill.default || Quill;
    this.editorId = this.randomString();
    
    this.toolbarId = this.randomString();
    this.$nextTick(() => {
      this.initialize(Quill);
    });
  },
  watch: {
    value(newVal) {
      if (newVal !== this.content) {
        this.pasteHTML(newVal);
      }
    },
  },
};
</script>
