import axios from "axios"



export const create = (item, token) => {
  return axios.post(process.env.VUE_APP_API_URL + `videos/`, {
    description: item.description,
    name: item.name,
    link: item.link,
    cover_media_id: item.cover_media_id,
    type: item.type,
    tags: item.tags,
    visible: item.visible || false,
   
   
  }, {
      headers: {
      Authorization: 'Bearer ' + token,
    }
  })
}

export const update = (id, item, token) => {
  return axios.put(process.env.VUE_APP_API_URL + `videos/` + id, {
    description: item.description,
    name: item.name,
    link: item.link,
    cover_media_id: item.cover_media_id,
    type: item.type,
    tags: item.tags,
    visible: item.visible || false,
    
  
  }, {
      headers: {
      Authorization: 'Bearer ' + token,
    }
  })
}

export const getById = (id, token) => {
  return axios.get(process.env.VUE_APP_API_URL + `videos/` + id, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })
}

export const get = (type, search, page, count, token) => {
  return axios.get(process.env.VUE_APP_API_URL + `videos/?search=${search}&count=${count}&page=${page}`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })
}

export const getList = (token) => {
  return axios.get(process.env.VUE_APP_API_URL + `videos/list/`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })
}

export const deleteR = (id, token) => {
  return axios.delete(process.env.VUE_APP_API_URL + `videos/${id}`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })
}

/*export const getByType = (type, search, page, count, token) => {
  return axios.get(process.env.VUE_APP_API_URL + `categories/type/${type}?search=${search}&count=${count}&page=${page}`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })
}*/
