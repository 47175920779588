var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('validation-provider',_vm._b({attrs:{"rules":_vm.rules,"name":_vm.name},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
var invalid = ref.invalid;
var validated = ref.validated;
return [_c('b-form-group',[(_vm.label)?_c('label',{staticClass:"form-control-label"},[_vm._v(" "+_vm._s(_vm.label)),(_vm.required)?_c('span',[_vm._v(" *")]):_vm._e()]):_vm._e(),_vm._t("default",function(){return [_c('input',_vm._g(_vm._b({attrs:{"hidden":"true","valid":valid,"required":_vm.required},domProps:{"value":_vm.value}},'input',_vm.$attrs,false),_vm.listeners))]},null,_vm.slotData),_c('div',{staticClass:"quill",class:[
            { 'is-valid': valid && validated && _vm.successMessage },
            { 'is-invalid': invalid && validated }]},[_c('div',{attrs:{"id":_vm.toolbarId}},[_c('div',{staticClass:"ql-formats"},[_c('button',{staticClass:"ql-bold"}),_c('button',{staticClass:"ql-italic"}),_c('button',{staticClass:"ql-underline"}),_c('button',{staticClass:"ql-link"}),_c('button',{staticClass:"ql-blockquote"}),_c('button',{staticClass:"ql-code"}),_c('button',{staticClass:"ql-image"}),_c('button',{staticClass:"ql-list",attrs:{"type":"button","value":"ordered"}}),_c('button',{staticClass:"ql-list",attrs:{"type":"button","value":"bullet"}})])]),_c('div',{ref:"editor",attrs:{"id":_vm.editorId,"name":_vm.name}}),_vm._t("error",function(){return [(errors[0])?_c('div',{staticClass:"invalid-feedback",staticStyle:{"display":"block"}},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]})],2)],2)]}}],null,true)},'validation-provider',_vm.$attrs,false))],1)}
var staticRenderFns = []

export { render, staticRenderFns }